import * as React from "react";
import { Helmet } from "react-helmet";

import { useStaticQuery, graphql } from "gatsby";
// import { MetaImageStatic } from './../../static/meta.jpg';

const MetaComponent = ({ pageName }) => {
  const title = pageName ? pageName : "";

  const { site } = useStaticQuery(query);

  const { defaultDescription, siteUrl, defaultImage } = site.siteMetadata;

  const metaImage = pageName === "Pro" ? "/proMeta.jpg" : defaultImage;

  const seo = {
    description: defaultDescription,
    image: `${siteUrl}${metaImage}`,
  };

  return (
    <Helmet>
      <title>{`KeyFi ${title} - Unlock The Future Of Finance`}</title>
      <meta charSet="utf-8" />
      <meta name="title" content="KeyFi - Unlock the future of finance" />
      <meta
        name="description"
        content="Manage your assets seamlessly on one intelligent DeFi platform."
      />
      <meta name="image" content={seo.image} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://keyfi.com/" />
      <meta
        property="og:title"
        content="KeyFi - Unlock the future of finance"
      />
      <meta
        property="og:description"
        content="Manage your assets seamlessly on one intelligent DeFi platform."
      />
      <meta property="og:image" content={seo.image} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://keyfi.com/" />
      <meta
        property="twitter:title"
        content="KeyFi - Unlock the future of finance"
      />
      <meta
        property="twitter:description"
        content="Manage your assets seamlessly on one intelligent DeFi platform."
      />
      <meta property="twitter:image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="canonical" href="https://keyfi.com" />
      <script type="text/javascript">
        _paq.push(["setDomains", ["*.keyfi.com", "app.keyfi.com"]]);
        _paq.push(["enableCrossDomainLinking"]);
      </script>
    </Helmet>
  );
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`;

export default MetaComponent;
