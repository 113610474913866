// import React from "react";
import styled from "styled-components";

export const lightTheme = {
  bg: "#FFFFFF",
  bg2: "#F8FCFD",
  text_grey: "#99A7C2",
  text_greyDark: "#555C68",
  text_primary: "#00CAED",
  text_primary2: "#00B2D1",
  text_primary_dark: "#066F7E",
  text_secondary: "#1F347E",
  navBg: "#FFFFFF",
  navHover: "#EFF8F9",
  navActive: "#EEFDFF",
  btnPrimaryBg: "#00CAEC",
  btnPrimaryColor: "white",
  borderBg: "#CCE8EB",
  btnSecondary: "#AEF3FF",
  tabsBg: "#EEFDFF",
  grey1: "#F8F9FC",
  grey2: "#E6ECF5",
  grey3: "#3B474E",
  grey4: "#DEF0F2",
  grey5: "#D3DEEE",
  brandGrey: "#F9F9F9",
  navy: "#1B3784",
  lightBlack: "#474D58",
  black1: "#555C67",
  white: "#FFFFFF",
  white1: "#F3F7FF",
  brandWhite: "#F7F8FA",
  orange: "#FF9F1C",
  red1: "#F65555",
  red2: "#FE7062",
  blue: "#192B66",
  lightBlue: "#F6FAFF",
  lightBlue2: "#DDEAFB",
  lightBlue3: "#9CA9C4",
  brandBlue: "#005AD3",
  blueTransparent: "#122A4B",
  brandBlack: "#1B232E",
  darkBlue: "#0046A2",
  yellow: "#FFEA32",
  proBlack: "#111417",
  proText: "#CED2D6",
  proTextHighlight: `#589FFF`,
  faqLink: "#58AFFF",
  faqCardBackground: "linear-gradient(231.19deg, #222222 0%, #182331 100%)",
  faqCardBackgroundPRO:
    "linear-gradient(116.14deg, #101926 -22.83%, rgba(74, 91, 114, 0) 123.39%)",
  languageBorder: "#738CA6",
  languageHover: "#295BA0",
};

export const breakPoints = {
  sm: 576, // 576
  md: 768, // TODO: Implement tablet layout
  lg: 992,
  xl: 1200,
  laptop: 1350,
};

export const BodyText = styled.p`
  color: ${(props) => (props.color ? props.color : props.theme.text_greyDark)};
  font-family: Telex, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : 300)};
  line-height: 24px;
  letter-spacing: 0px;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  text-align: ${(props) => (props.align === "center" ? `center` : `left`)};
`;
